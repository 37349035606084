.landing-page {
	text-align: center;
	/* margin-top: 1em; */
	height: 100%;
	width: 100%;
	/* display: flex;
	align-items: center; */
}
.slider-container > .slick-slider > .slick-list {
	margin-left: 20px;
}

.web-hub-title{
	color: #514b48;
    font-weight: bold;
    letter-spacing: 4px;
	text-align: left;
	font-size: 2.1rem;
	line-height: 1.5;
}

.web-hub-title-sub {
	font-weight: bold;
	color: #db8333;
	letter-spacing: 1.8px;
	text-align: left;
}

header h1 {
	font-size: 3rem;
	margin-bottom: 10px;
}

header p {
	color: #666;
	font-size: 1.2rem;
}

.main-section {
	margin-top: 1em;
	min-height: 75vh;
}

.main-section h2 {
	font-size: 2.5rem;
	margin-bottom: 20px;
}

.main-section p {
	color: #444;
	font-size: 1.2rem;
	line-height: 1.6;
	margin-bottom: 30px;
}

.cta-button {
	background-color: #007bff;
	color: #fff;
	border: none;
	padding: 12px 24px;
	font-size: 1.2rem;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.cta-button:hover {
	background-color: #0056b3;
}

footer {
	margin-top: 1em;
	color: #666;
	font-size: 0.9rem;
}

.sh-landing-page-landing-image-left-cover-con, .sh-landing-page-landing-content-right-side-con{
	width: 50%;
	text-align: left;
}

.sh-landing-page-lading-image{
	width: 100%;
}

.sh-landing-page-landing-description-contet-con{
	margin-top: 2em;
}
.sh-landing-page-get-started-icon{
	width: 0.4em;
}
.sh-landing-page-get-started-btn{
	margin-top: 3em;
	border: 1px solid;
	padding: 5px;
}
.sh-landing-page-get-started-icon{
	margin-right: 5px;
}

.landing-page-full-height-con{
	min-height: 100vh;
	max-height: 100vh;
	display: flex;
	align-items: center;
}

.landing-page-section-1-con{
	background-image: url("../../assets/images/Karthik-Iyer’s-banner-image.jpeg");
	background-size: contain;
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;
}
.landing-page-section-1-con-home{
	background-image: url("../../assets/images/Karthik-Iyer’s-banner-image.jpeg");
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}
.sh-landing-page-level-up-line{	
	font-size: 3.8em;
	font-weight: 600;
	font-family: 'Poppins';
	line-height: 1.4;
}

.sh-landing-page-your-boss-line{
	font-size: 1.7em;
	font-weight: 400;
	font-family: 'Poppins';
	line-height: 2;
	margin-top: 1.2em;
}


.sh-landing-page-section-1-row-con{
	text-align: left;
}

.sh-landing-page-lottie-down-arrow{
	width: 13em;
	position: absolute;
	bottom: 0;
	cursor: pointer;
}

.sh-landing-page-join-us-button {
	padding: 25px 35px;
	font-size: 18px;
	width: 12.3em;
	border: none;
	border-radius: 35px;
	cursor: pointer;
	animation: gradientAnimation 10s ease infinite;
	margin-top: 2.6em;
	z-index: 2;
}
.sh-landing-page-join-us-free-trial-button {
	padding: 15px 30px;
	font-size: 32px;
	width: 12em;
	background-size: 800% 800%;
	border: none;
	border-radius: 30px;
	cursor: pointer;
	animation: gradientAnimation 10s ease infinite;
	margin-top: 2.6em;
	z-index: 2;
	height: 3.5em;
	width: 14em;
	border-radius: 70px;
}

.sh-landing-page-lets-gets-btn-link-tag, .sh-landing-page-lets-gets-btn-link-tag:hover{
	color: inherit;
	text-decoration: none;
}

/* @keyframes gradientAnimation {
	0% {
			background-position: 0% 50%;
	}
	50% {
			background-position: 100% 50%;
	}
	100% {
			background-position: 0% 50%;
	}
} */

.sh-landing-page-join-us-button > a {
	/* color: white; */
	text-decoration: none;
}

.sh-landing-page-tagline-title{
	font-size: 23px;
	margin-top: 2.2em;
}

.sh-landing-page-video-con{
	width: 80%;
	border-radius: 15px;
	position: relative;
	z-index: 3;
}

.landing-page-combined-sec-2-3-background-image{
	background-image: url("../../assets/images/Violin-image.jpeg");
	/* background-size: cover; */
	background-size: contain;
	/* background-size: cover; */
	/* background-position: center; */
	background-position: calc(100% - 50%);
	/* background-position: 100%-190px ; */
	background-repeat: no-repeat;
	/* background-repeat: repeat; */
}

/* .sh-landing-page-section-2-black-background{
	height: 98vh;
	display: grid;
	background: black;
	position: relative;
	z-index: 1;
	justify-items: center;
	align-items: center;
} */

.sh-landing-page-section-2-top-white-background{
	height: 50vh;
	/* background: white; */
	z-index: 2;
	width: 100%;
	position: absolute;
	top: 0;
}

.landing-page-section-3-con{
	/* background-color: #000000; */
	padding: 2.5em 0px 2.5em 0px;
}

.sh-landing-page-section-2-text-con{
	font-size: 5em;
	line-height: 1.3;
	font-weight: 600;
	letter-spacing: 1px;
}

.sh-landing-page-section-2-row-con{
	width: 100vw;
}
/* .slider-container {
  position: relative;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-30%);
}

.slider-arrow.prev {
  left: 100px;
}

.slider-arrow.next {
  right: 0;
}
.slick-slide {
  padding: 0 5px;
}
.slick-slide img {
	max-width: 100%;
} */

/* .module-card-image{
	min-height: 44em;
	max-height: 50em;
} */
.module-card-image-con{
	/* height: 60vh; */
	overflow: hidden;
	border-radius: 1em;
}

.slick-next{
	right: -50px;
}

.slick-slide{
	padding: 0em 1em;
}

.module-card-image{
	width: 100%;
}
.module-card{
	position: relative;
	cursor: pointer;
}

.slick-slide img{
	transition: transform 0.3s ease;	
}

.slick-slide img:hover{
	/* scale: 1.1; */
	transform: scale(1.1);
}
.module-card-title:hover{
	.slick-slide img:hover{
		transform: scale(1.1);
	}	
}

.module-card-title{
	position: absolute;
	bottom: 0;
	width: 100%;
	/* height: 100%; */
	background-color: #000000a6;
	display: flex;
	align-items: center;
	text-align: left;
	padding: 1em 2em;
	min-height: 7em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em;
}

.slick-next:before, .slick-prev:before{
	content: "" !important;
}

.slick-arrow{
	font-size: 6em;
	/* color: #fff; */
	transition: scale 0.3s;
	z-index: 5;
}
/* .slick-arrow:hover{
	color: #fff;
} */
.slider-container > .slick-slider > .slick-prev, .slider-container > .slick-slider > .slick-next{
	font-size: 5.5em;
	transition: scale 0.3s;
	z-index: 5;
	top: 45% !important;
}
.slider-container > .slick-slider > .slick-next > .sh-landing-page-right-arrow{
	margin-left: 5vh;
}
.slider-container > .slick-slider > .slick-prev > .sh-landing-page-left-arrow{
	margin-right: 5vh;
}
/* .slider-container > .slick-slider > .slick-prev, .slider-container > .slick-slider > .slick-next:hover{
	color: #fff;
} */

.slick-prev{
	left: -60px !important;
}

.landing-page-section-4-con{
	justify-content: center;
}

.module-card-title-content{
	font-size: 17px;
	text-align: left;
	height: 100%;
	line-height: 1.5;
}

.sh-landing-page-section-4-title{
	text-align: left;
	margin-bottom: 1.5em;
	margin-left: 0.5em;
	font-size: 45px;
}

.vertical-tabs {
  display: flex;
}

.tab-list {
	width: 37%;
	overflow-y: auto;
	text-align: left;
	font-size: 17px;
	line-height: 1.5;
}

.tab-item {
  padding: 10px;
  cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
}


.tab-content {
	width: 61%;
  flex-grow: 1;
  padding: 20px;
  transition: opacity 0.5s ease-in-out; /* For fade animation */
	text-align: left;
  
}

.landing-page-section-5-content-side-heading{
	font-size: 22px;
	font-weight: bold;
	/* text-transform: uppercase; */
}

.landing-page-section-5-content-side-content-para{
	font-size: 14px;
	margin-block: 15px;
	line-height: 3;
}
.landing-page-section-6-content-side-content-para{
	font-size: 14px;
	margin-block: 15px;
	line-height: 2;
	text-align: left;
}

.sh-accordian-indivial-panel-con  > h2 > button{
	font-size: 20px;
	padding: 20px 10px 20px 10px;
	border-bottom: 0.5px solid #656565;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.sh-accordian-content-faq-con > div{
  border-bottom: 0.5px solid #656565;
}

.tab-content.fade-in {
  opacity: 0;
}

.tab-content.slide-in {
  transform: translateX(100%);
}

.tab-item-inner-con{
	/* display: flex;
	gap: 15px;
	align-items: center; */
	text-transform: uppercase;
}

.sh-landing-page-vertical-tab-dummy-image{
	width: 100%;
}

.landing-page-section-6-con{
	padding-block: 5em;
}

.landing-page-section-6-accordion {
  /* width: 70%; */
  /* max-width: 600px; */
  /* margin: 0 auto; */
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  overflow: hidden;
  /* margin-bottom: 10px; */
}

/* .landing-page-section-5-con{
	background-color: #000;
} */

/* .landing-page-section-6-con{
	background-color: #000;
} */

.landing-page-section-6-accordion-item {
  border-bottom: 2px solid #756e6e;
}

.landing-page-section-6-accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 30px 0px;
  cursor: pointer;
  /* background-color: #f7f7f7; */
  border: none;
  outline: none;
  transition: background-color 0.2s ease;
	/* color: white; */
	font-size: 16px;
	text-align: left;
	line-height: 1.4;
}
/* 
.landing-page-section-7-con{
	background-color: #000;
} */

.sh-landing-page-collage-image{
	/* width: 100%; */
	width: 65%;
}

/* .landing-page-section-6-accordion-header:hover {
  background-color: #e2e2e2;
} */

.landing-page-section-6-accordion-content {
  max-height: 0;
  overflow: hidden;
  padding: 0 15px;
	text-align: left;
	line-height: 3;
	font-size: 15px;
}

.landing-page-section-6-symbol {
  font-weight: bold;
  font-size: 20px; /* Increased font size for "-" symbol */
}


/* footer stylings start */

.sh-landing-page-footer-section-1-con{
	display: flex;
	justify-content: space-between;
}

.sh-landing-page-footer-section-1-column-1{
	width: 30%;
	text-align: left;
}

.sh-landing-page-footer-section-1-column-2{
	width: 35%;
	text-align: left;
}

.sh-landing-page-footer-section-1-column-3{
	width: 26%;
	text-align: left;
}

.sh-landing-page-logo-icon{
	width: 8em;
}

.sh-landing-page-text-content{
	/* color: #bfbfbf; */
	font-size: 13px;
	line-height: 2;
	/* margin-block: 20px; */
	font-weight: normal;
}

.sh-landing-page-head-content{
	font-size: 21px;
	font-weight: bold;
	margin-bottom: 50px;
}


.sh-landing-page-footer-display-flex {
	display: flex;
	/* align-items: center; */
	gap: 10px;
	margin-bottom: 20px;
}

.sh-landing-page-about-text{
	margin-top: 20px;
	width: 55%;
}

.sh-landing-page-footer-icon {
	/* width: 2em; */
	font-size: 1.7em;
}

.sh-landing-page-footer-a-tag-styles, .sh-landing-page-footer-a-tag-styles:hover{
	cursor: pointer;
	color: inherit;
	text-decoration: none;
}

.sh-landing-page-footer-copyright-text{
	text-align: left;
	padding-bottom: 2em;
}

.sh-landing-page-footer-container{
	/* background: black; */
	margin-top: 0px;
	padding-top: 4em;
}

.landing-page-section-2-con, .landing-page-section-3-con{
	background-color: #0000008f;
}

.landing-page-section-4-con > div {
	min-width: 80%;
	max-width: 80%;
}

.sh-line-breaker-to-hide-mobile{
	display: block;
}

.landing-page-section-4-con-mobile-view{
	display: none;
}

.sh-landing-page-offer-section-image{
	min-height: 27em;
}
.sh-landing-page-offer-section-image-mobile{
	min-height: 12em;
}

.sh-landing-page-online-indian-section-image{
	min-height: 40em;
}

.sh-landing-page-home-route-banner-section{
	/* background-position: unset; */
	/* background-image: url("../../assets/images/landingCoverMobile.jpg"); */
	background-color   : unset;
	min-height        : 92vh;
	max-height         : 90vh;
	background-position: right;
}

.slider-container > .slick-slider > .slick-list {
	margin-left: 20px;
}

@media (min-width: 1200px) and (max-width: 1799.98px) {
	/* Styles for large desktops */
	/* .landing-page-section-1-con{
		background-size: cover;
		min-height: 80vh;
		max-width: 100vw;

	} */
	/* .module-card-image-con{
		min-height: none;
		max-height: none;
	} */
	
}

@media (min-width: 1200px) {
	/* Styles for desktops */
	/* .landing-page-section-1-con{
		background-size: cover;
		min-height: 100vh;
		min-width: 100vw;
	} */
	/* .module-card-image-con{
		min-height: none;
		max-height: none;
	} */
	/* .sh-landing-page-section-4-title{
		margin-top: 5em;
	} */
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	/* .module-card-image-con{
		min-height: none;
		max-height: none;
	} */
	
	/* Styles for small desktops and large tablets */
	/* .landing-page-section-1-con{
		background-size: cover;
		min-height: 90vh;
		min-width:80vw;

	} */
}

@media (min-width: 768px) and (max-width: 991.98px) {
	/* .landing-page-section-1-con{
		background-size: cover;
		min-height: 40vh;
		min-width: 40vw;
	} */
	.landing-page-section-6-accordion-content{
		line-height: 2;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	/* Styles for landscape phones and small tablets */
	/* .landing-page-section-1-con{
		background-size: cover;
	} */
}

@media (max-width: 575.98px) {
	/* Styles for phones (portrait) */
	.landing-page-full-height-con{
		min-height: fit-content;
		max-height: fit-content;
	}
	
	.mobile-view-landing-page-full-height-con{
		min-height: 100vh;
		max-height: 100vh;
		display: flex;
		/* align-items: center; */
		align-items: flex-end;
        padding-bottom: 7vh;
	}

	.sh-landing-page-level-up-line{
		font-size: 2em;
		line-height: 1.7;
	}

	.sh-landing-page-your-boss-line{
		font-size: 1.3em;
		margin-top: 1.8em;
		line-height: 1.6 ;
	}
	
	.sh-landing-page-video-con{
		width: 100%;
	}

	.sh-landing-page-section-2-black-background{
		padding: 10px;
	}
	.landing-page-section-1-con, .landing-page-section-1-con-home{
		background-image: url("../../assets/images/landingCoverMobile.jpg");
		background-color: #0000009c;
        background-blend-mode: color;
        background-position: inherit;
	}
	/* .landing-page-section-1-con{
		background-size: cover;
	} */
	.sh-landing-page-join-us-button{
		font-size: .9em;
	}
	.sh-landing-page-section-2-text-con{
		font-size: 2em;
	}
	.sh-landing-page-join-us-free-trial-button{
		font-size: 1.1em;
	}
	.sh-landing-page-section-2-row-con{
		padding-top: 3em;
	}
	.sh-landing-page-section-4-title{
		font-size: 1.7em;
		text-align: center;
		margin-left: 0px;
	}
	.landing-page-section-4-con{
		padding-block: 2.5em;
	}
	.slider-container > .slick-slider > .slick-prev, .slider-container > .slick-slider > .slick-next{
		font-size: 3.5em;
		right: -30px;
	}
	.slick-slide{
		padding-right: unset;
	}
	/* .slick-slide{
		margin-right: 20px;
	}
	.slick-list{
		padding-left: 20px;
	}
	.slick-track{
		display: flex;
	} */
	 /* .sh-landing-page-prev-arrow{
		padding-right: 20px;
	 } */
	.landing-page-section-4-con > div{
		min-width: 85%;
		max-width: 85%;
	}
	.landing-page-section-4-con-desktop-view{
		display: none;
	}
	.landing-page-section-4-con-mobile-view{
		display: block;
	}
	.sh-line-breaker-to-hide-mobile{
		display: none;
	}
	/* .module-card-image{
		width: 100%;
	} */
	.module-card-title{
		width: 100%;
	}
	.sh-accordian-indivial-panel-con > h2 > button > svg {
		display: none;
	}
	.sh-accordian-indivial-panel-con > h2 > button > span {
		font-size: 0.9em;
		font-weight: normal;
		/* text-transform: uppercase; */
	}
	.landing-page-section-5-content-side-heading{
		font-size: 18px;
		margin-top: 15px;
	}
	.sh-landing-page-vertical-tab-con{
		text-align: left;
	}
	.sh-landing-page-section-7-row-con > h1 {
		line-height: 1.5;
		margin-top: 2em;
		font-size: 1.2em;
		font-weight: normal;
	}
	.sh-landing-page-collage-image{
		width: 100%;
	}
	.landing-page-section-6-accordion{
		position: relative;
		top: -30px;
	}
	.module-card-title-content{
		font-size: 13px;
		margin-bottom: 10px;
	}
	.sh-landing-page-footer-section-1-con{
		display: unset;
		justify-content: unset;
	}
	.sh-landing-page-footer-section-1-column-1, .sh-landing-page-footer-section-1-column-2, .sh-landing-page-footer-section-1-column-3{
		width: 100%;
	}
	.sh-landing-page-head-content{
		margin: 1em 0em;
	}
	.landing-page-section-6-con{
		padding-bottom: 0px;
	}
	.sh-landing-page-section-4-title-mobile{
		margin-bottom: 0.5em;
	}
	.sh-landing-page-online-indian-section-image{
		min-height: 20em;
	}
	.sh-landing-page-home-route-banner-section{
		/* background-position: unset; */
		background-color   : unset;
		min-height        : 85vh;
		max-height         : 85vh;
	}
	.sh-landing-page-title-above-space{
		margin-top: 1em;
	}
	.slick-prev{
		left: -30px !important;
	}
	.slider-container > .slick-slider > .slick-next > .sh-landing-page-right-arrow{
		margin-left: unset;
	}
	.landing-page-section-6-accordion-content{
		line-height: 2;
	}
	/* .module-card-title-content{
		font-size: 14px;
		padding-bottom: 8px;
	}
	.tab-content{
		padding: 7px 10px;
	}
	.landing-page-section-5-content-side-content-para{
		font-size: 12px;
	}
	.landing-page-section-5-con{
		padding-block: 3em;
	} */
}

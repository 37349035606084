@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.App {
  text-align: center;
}

.sidebar-and-content {
  display: flex;
  
}

.app-header-con {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0px 10px !important;
  background: #b1e2e6 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.visualizer {
  width: 100%;
}

.poc-btn-con {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
}



.current-values-table {
  // margin: auto;
  padding: 10px;
  // margin-top: 20px;
  margin-bottom: 20px;
  min-width: 450px;
}

.current-value-table-cat, .current-value-table-val {
  text-align: left;
  padding: 5px;
}

.current-values-table, .current-value-table-cat, .current-value-table-val {
  border-collapse: collapse;
  border: 1px solid black;
}

.current-value-table-cat, .current-value-table-val {
  min-width: 150px;
  text-align: right;
}

.current-value-table-val {
  text-align: center;
}

.controls {
  margin-left: auto;
  padding: 10px;
  margin-bottom: 20px;
  min-width: 500px;
}

.controls-cat, .controls-val {
  text-align: left;
  padding: 0px 5px;
}

.controls-val{
  min-width: 12em;
  max-width: 12em;
}

.controls select {
  min-width: 150px;
}

/* -------------------------------- */

.timeline-con {
  position: absolute;
  bottom: 0;
  /* width: 1800px; */
  width: 100vw;
  margin-bottom: 10px;
  overflow: hidden;
}

.timeline {
  transform: perspective(100px) rotateX(4deg);
  background-color: grey;
  width: 3000px;
  height: 150px;
  margin-left: -100px;
  /* position: absolute; */
  /* bottom: 0; */
  overflow: hidden;
}

/* .timeline :first-child {
  margin-left: 160px;
} */

.timeline-tile-tr-con {
  /* border: 1px solid red; */
  display: table;
  float: right;
}

.timeline-tile-tr {
  width: 29px;
  height: 148px;
  background: #555555;
  border-left: 1px solid grey;
  display: table-cell;
}

.timeline-tile-wh-con {
  /* border: 1px solid rgb(101, 198, 32); */
  display: table;
  float: right;
}

.timeline-tile-wh {
  width: 30px;
  height: 148px;
  background: #808080;
  border-left: 1px solid #555555;
  display: table-cell;
}

.timeline-tile-con {
  position: absolute;
  right: 0;
}

.timeline-note-pos-con {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 250px;
  bottom: 50px;
  background: white;
  border-radius: 20px;
}

.timeline-tile-note {
  width: 45px;
  height: 20px;
  border-radius: 20px;
  background: green;
  /* position: absolute; */
  /* left: 500px; */
  /* bottom: 10px; */
  font-size: 12px;
  padding: 5px;
  transform: perspective(100px) rotateX(-4deg);
  float: right;
  margin-top: 85px;
}

.timeline-tile-note-con {

  bottom: 10px;

}

/* login start */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  width: 400px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.login-form {
  padding: 40px;
}

.login-form label {
  font-weight: bold;
}

.login-form input[type="email"],
.login-form input[type="password"] {
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 12px;
  width: 100%;
}

.login-form button[type="submit"] {
  border-radius: 5px;
  padding: 12px;
  width: 100%;
}

.forgot-password {
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}

/* login end */

/* Header start */
.app-header {
  background: linear-gradient(to right, #f1f1f1, #e1e1e1);
  color: #333;
  height: 8vh;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brand-name {
  font-size: 24px;
  font-weight: bold;
}

.sign-out-btn {
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-out-btn:hover {
  background-color: rgba(51, 51, 51, 0.1);
}

/* Header end */

body {
  overflow: auto !important;
}

.dashboard-row {
  row-gap: 1em;
  column-gap: 1em;
}

.dashboard-card-header {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.dashboard-title {
  /* font-size: 2rem; */
  font-weight: bold;
  /* color: #333; */

}

.dashboard-subtitle {
  font-size: 1.2rem;
  // color: #666;
}

.dashboard-row col {
  margin-top: 1em;
}

.dashboard-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  cursor: pointer;
  min-height: 24vh;
  max-height: 24vh;
}

.dashboard-card .dashboard-card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color : #333;
  margin: 0px !important;
}

.dashboard-card .dashboard-card-text {
  font-size: 1rem;
  color : #666;
}

/* POC Start */

.poc-title-col   {
  margin-top: 1.3em !important;
  font-size: 1.5rem;
  font-weight: bold;
}

.poc-back-btn {

  color: #0d6efd;
  font-weight: bold;
  cursor: pointer;

}
/* POC End */

.sh-user-profile-cover-image-con{
  width: 100%;
  height: 35vh;
}
.sh-user-profile-cover-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sh-user-profile-image-con{

  width: fit-content;
  

}
.sh-user-profile-image{
  // width: 100%;
  /* height: 10rem; */
  /* min-width: 10rem; */
  object-fit: contain;
  padding: 5px;
  width: 4rem;
  border-radius: 50%;
}
// .sh-user-profile-user-details-con{
  // margin-top: 1.5em;
  // margin-left: 3em;
// }
.sh-user-profile-name-type-con{
  display: flex;
  align-items: center;
}
.sh-user-profile-user-type{
  padding: 5px 9px 6px 8px;
  border-radius: 12px;
  margin-left: 20px;
}
// .sh-user-profile-tag-name{
  
// }
.sh-user-profile-tab-section-details{
  display: flex;
  width: 100%;
  min-width: 13em;
  align-items: center;
  column-gap: 1rem;
  // justify-content: space-between;
}
.sh-user-profile-tab-section-container{
  margin-top: 20px;
  margin-left: -3px !important;
  padding-top: 20px;
}
.sh-user-profile-user-details-tabs-section{
  margin-top: 2em;
}
.sh-user-profile-data-labels-section{
  font-size: 1rem;

}
// .sh-user-profile-data-labels-section-padding{
//   padding-right: 10px;
// }
.sh-user-profile-basic-info-edit-button{
  width: 60px;
  padding: 6px;
  border-radius: 10px;
  font-weight: 700;
}
// .sh-edit-user-profile-page-con > div > div > div > div > div > .MuiButtonBase-root[tabIndex="0"] {
//   background-color: #fff;
// }
.sh-user-edit-profile-basic-secleted-tab-title{
  margin: 1em 0px 2em 0px;
}
.sh-user-edit-profile-basic-secleted-tab-title-cover{
  margin: 1em 0px;
}
.sh-user-edit-profile-tabs-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sh-user-edit-details-save-button{
  margin-top: 3em;
}
.sh-user-edit-details-save-button-disabled{
  cursor: not-allowed;
}
.sh-user-edit-profile-label-div{
  margin-top: 2em;
}
.sh-edit-profile-upload-profile-image{
  text-align: center;
  border-radius: 10px;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sh-edit-profile-upload-profile-image-disabled{
  text-align: center;
  border-radius: 10px;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sh-edit-profile-upload-image-button-style{
  padding: 10px;
  border-radius: 5px;
  width: 10em;
  margin: auto;
  margin-top: 1.2em;
}
.sh-edit-user-profile-image-upload-section{
  height: 100%;
}
.sh-user-profile-delete-image-content{
  margin-top: 1em;
}
.sh-edit-user-profile-show-staus-content{
  padding: 12px;
  margin-top: 2em;
}

.rs-loader-spin::after {
  border-color: $primaryMain transparent transparent !important;
}

// Intro Page

.intro-page-next-btn-con {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;

}
.sh-user-profile-details-con{
  padding: 15px;
  display: flex;
  align-items: center;
}
.sh-user-details-basic-info-section, .sh-user-details-edit-btn-section{
  width: 50%;
}
.sh-user-details-edit-btn-section{
  text-align: right;
}
.sh-user-profile-tag-name{
  margin-top: 5px;
  margin-left: 6px;
  text-align: left;
  text-transform: capitalize;
}
.sh-level-page-left-side-container{
  width: 100%;
  // display: grid;
  // justify-content: center;
}
.sh-level-page-right-side-container{
  width: 67%;
  text-align: center;
  // margin-top: 3em;
}
// .sh-level-page-left-side-card-container{
//   width: 90%;
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minMax(250, 1fr));
  // grid-template-columns: repeat(auto-fit, minMax(180px, 0.4fr));
  // grid-template-columns: repeat(auto-fit, minMax(160px, 180px));
  // grid-template-columns: repeat(auto-fit, 180px);
  // grid-gap: 20px;
  // justify-content: center;
// }

.sh-level-page-left-side-individual-cards{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: none;
}

.sh-level-page-left-side-individual-cards:hover {
  border: 1px solid;
}
.sh-level-page-left-side-card-container-header{
  text-align: center;
}
.sh-level-page-container{ 
  display: flex;
  flex-direction: row;
  // align-items: center;
}
.sh-level-page-card-content{
  text-align: center;
  background: none !important;
}
.sh-level-page-cards-section-icon{
  font-size: 25px;
  margin-bottom: 15px;
}
.sh-skill-level-page-card-image{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.sh-level-page-individual-cards-border{
	border: 1px solid;
}
.sh-level-page-right-side-image{
  width: 60%;
}
.sh-level-page-card-con-1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4em;
}
.sh-level-page-card-con-2{
  margin-top: 3em;
}
.sh-level-page-left-side-header{
  // margin-top: 1em;
  margin-bottom: 2em;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.5em;
  font-style: italic;
}
.landing-page-inner-login {
	// font-family: Arial, cursive;
	text-align: center;
	/* margin-top: 1em; */
	height: 100% !important;
	width: 100% !important;
	display: flex;
	align-items: center;
}

.web-hub-title{
	color: #514b48;
  font-weight: bold;
  // font-family: cursive;
  letter-spacing: 4px;
	text-align: left;
	font-size: 2.1rem;
	line-height: 1.5;
}

.web-hub-title-sub {
	font-weight: bold;
	color: #db8333;
	// font-family: cursive;
	// font-style: oblique;
	letter-spacing: 1.8px;
	text-align: left;
}

.sh-landing-page-landing-image-left-cover-con, .sh-landing-page-landing-content-right-side-con{
	width: 50%;
	text-align: left;
}

.sh-landing-page-lading-image{
	width: 100%;
}

.sh-landing-page-landing-description-contet-con{
	margin-top: 2em;
}
.sh-landing-page-get-started-icon{
	width: 0.4em;
}
.sh-landing-page-get-started-btn{
	margin-top: 3em;
	border: 1px solid;
	padding: 5px;
}
.sh-landing-page-get-started-icon{
	margin-right: 5px;
}

.selected-skill-level {
  position: absolute;
  bottom: -12px;
  z-index: 999;
  background: white;
  border-radius: 10em;
}

// course list page start

.course-list-header-col-con {
  /* margin: 2em 0px; */
  /* margin-bottom: 2em; */
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.5em;
  font-style: italic;
  // min-height: 200px;
  position: relative;
}

.course-list-heading-tab-col-content-con {
  text-align: left;
  padding: 2rem;
  align-items: center !important;
  column-gap: 2em !important;
}

// .course-list-main-tab-con {
//   display: flex;
//   align-items: center;
//   column-gap: 3em;
//   margin: 3rem 0 0;
// }

.course-list-header-con {
  background-image: url("../images/Rectangle_40.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 160px;
  margin-right: -40px;
  margin-left: -50px;
  
}

.course-module-list-div-con {
  width: 100%;
  background: #000000c7 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 11px 11px;
  opacity: 1;
  opacity: 1;
  padding: 0 13px;
}

.course-desc-con {
  margin: 1em 0;
  letter-spacing: 0.5px;
  text-align: left;
  min-height: 3em;
  max-height: 3em;
}

.course-duration-con {
  text-align: left;
  font-style: oblique;
  font-weight: 500;
  letter-spacing: 0.5px;

}

.course-duration-con span {
  font-style: normal;
  font-weight: bolder;
}

.course-time-lin-con {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sh-list-modules-filter-tab-con{
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}
.sh-list-modules-filter-tab{
  padding: 10px 15px 10px 15px;
  width: 14em;
  margin-right: 1em;
  border-radius: 15px;
  font-weight: normal;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.sh-list-card-page-card-container {
  display: none;
}
.sh-list-modules-filter-tab-tablist-con .MuiTabs-flexContainer  {
  border-bottom: none;
}
.sh-margin-zero > div, .sh-margin-zero > div > div {
  margin: 0px !important;
}

.sw-course-list-tab-main-con {
  // display: flex;
  width: 100%;
  column-gap: 1em;
  position: relative;
}

.sw-course-list-tab-con-1 {
  width: 240px;
  border-radius: 10px;
  max-height: 536px;
  min-height: 536px;
  overflow: auto;
  position: sticky;
  top: 93px;
}

.sw-course-list-main-con-2 {
  border-radius: 10px;
  padding: 2.5em;
  
}

// .sw-course-page-module-list-row {
  // min-height: 370px;
  // max-height: 370px;
  // overflow: auto;
// }

// course list page end
.sh-user-edit-page-mobile-view-view-profile-button{
  gap: 10px;
  margin-bottom: 1em;
  display: none;
}
.sh-user-edit-page-mobile-view-view-profile-button-text{
  font-size: 14px;
  font-weight: bold;
}

// module intro start
.sh-module-intro-container{
  height: 100%;
}
.sh-module-intro-row{
  display: flex;
  // align-items: center;
  // margin-top: 3em;
  // height: 100%;
}
.sh-module-intro-column-left{
  width: 50%;
}
.sh-module-intro-column-right{
  width: 50%;
}
.sh-module-intro-video-section-con{
  width: 100%;
  margin-top: 1.2em;
}
.sh-module-intro-left-side-description-content{

  margin: "1em 0 1em 1em";

  border-radius: 5px;
  margin-top: 1.2em;
  
  letter-spacing: 0.5px;
  line-height: 1.8;
}
.sh-module-intro-left-side-cost-content{
  font-size: 16px;
  padding-block: 2em;
  // padding-left: 2em;
}
.sh-module-intro-enroll-now-btn{
  border-radius: 2em;
  // margin-top: 2em;
}
.sh-back-btn-component-span{
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
// module intro end

// announcement start

.sh-announcement-post-content-image{
  // width: 100%;
  object-fit: cover;
  height: 100%;
}

.sh-community-page-content-left-side-con{
  width: 30%;
  height: 18.5em;
}

.sh-community-page-content-right-side-con{
  width: 70%;
}

// .sh-announcement-page-register-delete-icon-con{
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

.sh-announcement-page-register-text-card{
  padding: 10px 20px;
  border-radius: 20px;
}

.sh-announcement-page-like-delete-btn-con{
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.sh-announcement-page-like-only-btn-con{
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.sh-announcement-card-con {
  width: 70%;
  margin-bottom: 2em;
}

.sh-announcement-banner-text{
  font-size: 30px;
}

.sh-announcement-cards-container{
  width: 100%;
  // min-height: 77vh;
  // max-height: 77vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.sh-announcement-card-post-title, .sh-announcement-card-post-content{
  padding-bottom: 0.5em;
}
.sh-announcement-card-post-content{
  min-height: 10em;
  // max-height: 10em;
  // overflow: auto;
}

.sh-announcement-page-seperate-row-con{
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.sh-announcement-page-seperate-row-con-editor-con{
  margin-bottom: 1em;
  width: 95%;
}

.sh-announcement-page-left-label-side{
  width: 75%;
}

.sh-announcement-screen-addpost-cancel-con{
  display: flex;
  justify-content: flex-end;
}

// announcement end

.sh-list-modules-lock-icon-videos-text-con{
  display: flex;
  justify-content: space-between;
}

// payment start

.sh-payment-cards-outer-con{
  align-items: flex-start;
  display: flex;
  padding: 15px;
  column-gap: 10px;
  justify-content: center;
}

.sh-payment-cards-con{
  width: 25em;
}

.sh-payment-page-section-package{
  text-align: center;
  position: relative;
  top: -15px;
}

.sh-payment-confirmation-btn-dialog{
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;
}

// payment end

.sh-list-modules-over-lay{
  // position: absolute;
  // top: 0;
  // height: 100%;
  // display: flex;
  // align-items: center;
  // background-color: #00000089;
  // color: white;
  // padding: 15px;
  // width: 100%;
  // justify-content: center;
  // border-radius: 10px;
  position: absolute;
  top: 0;
  height: 100%;
  display: grid;
  background-color: #000000a8;
  color: white;
  padding: 15px;
  width: 100%;
  justify-content: center;
  border-radius: 10px;
  justify-items: center;
  align-items: center;
}

// video page start

.sh-video-player-pdf-download-btn{
  display: flex;
  gap: 10px;
  width: fit-content;
  align-items: center;
  cursor: pointer;
}
.ss-video-file-name-with-duration {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ss-video-file-name {
  width: 80%;
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-size: 0.75rem;
}
.ss-video-file-duration {
  width: 20%;
  text-align: right;
}
// video page end

// dropzone comonent start

.sh-dropsone-component-upload-icon{
  font-size: 6em;
}

// dropzone comonent end

// dropzone comonent start

.sh-dropsone-component-upload-icon{
  font-size: 6em;
}

// dropzone comonent end


// list course start 

// list course start
.sh-listlesson-delete{
  cursor: pointer;
  font-size: 16px;
}
.sh-refresh-rotate-icon{
  width: 50%;
  text-align: right;
} 
.sh-editcourse-parent-row{
margin-top: 30px;
}
.sh-list-course-course-description{
display: flex;
flex-direction: row;
align-items: center;
margin-top: 20px;
}
.sh-list-lesson-select-lesson{
  display: flex;
  padding-top: 10px;
}
.sh-list-course-course-module{
display: flex;
flex-direction: row;
align-items: center;
margin-top: 20px;
}
.sh-add-edit-course-cancel-btn{
  border: 1px solid;
}
.sh-list-course-course-typo{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sh-list-lesson-padding-right{
  padding-right: 30px;
}


.sh-listlesson-delete:hover{
  color: #f94449 !important;
  cursor: pointer;
}
.sh-fontawesome-edit-delete{
  display: flex;
  // gap: 2px;
  cursor: pointer;
  text-align: center;
}
.sh-fontawesome-edit:hover{
color: black !important;
}
.sh-fontawesome-edit{
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;

}

// Add-level-start
.list-level-data-table{
  margin-top: 25px;
}
.sh-add-module-des-pad{
  padding: 15px 7px 0px 15px;
}
.list-level-data-table-col-width{
  width: 100%;

}
.list-level-add-label{
  border: none;
  height: 30px;
}
.sh-module-intro-add{
  width: 50%;
  text-align: right;
}
.list-level-min-width-select{
  min-width: 200px;
}
// Add-level-end

// add-module-start

.sh-add-module-multi-description-pad{
  padding: 0px 20px 20px;
}
.sh-sh-add-module-multi-description-add-paddingtop{
  padding: 20px 0px;
}
.sh-add-module-media-upload-dropzone-padding{
  padding: 0px 20px;
  margin-top: 15px;
  height:200px;
}
// add-module-end

// Add lesson start
.sh-upload-disabled{
  cursor: not-allowed;
}
.sh-list-lesson-delete-icon-styles{
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
}
.sh-enter-lesson-align-center{
  align-items: center;
}
.sh-ashkey-margin-top{
  margin-top: 1em;
}
.sh-add-lesson-typo-add-lesson{
  display: flex;
  align-items: flex-start;
}

.sh-add-lesson-enter-lesson-col{
  width: 60%;
  display: flex;
  flex-direction: column;
}
.sh-add-lesson-select-course{
  display: flex;
  align-items: center;
  width: 100%;
}
.sh-add-lesson-marginTop{
  margin-top: 1em;
}
.sh-add-lesson-level{
  display: flex;
  align-items: center;
  width: 100%;
}
.sh-add-lesson-video{
  width: 40%;
padding-left: 40px;
}
.sh-add-lesson-pad{
  padding-left: 40px;
}
.sh-addlesson-marginTop{
  margin-top: 30px;
}
.sh-addlesson-select-label{
  // width: 300px;
  min-width: 500px;
  height: 40px;
  padding: 5px;
  margin: 10px;
}
.css-83j2yf-control{
  height: 40px;
  
}
.rs-col {
  padding-left: 0px;
}
.sh-addlesson-enter-lesson-name{
  // width: 100%;
  margin-top: 20px;
  display: flex; 
  align-items: center;
  justify-content: center;
}
.sh-addlesson-select-input{
  width: "50%";
}
.sh-add-lesson-cancel{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.sh-add-lesson-select-module{
  width: 100%;
  display: flex;
  align-items: center;

}
.sh-add-lesson-select-input{
  display: flex;
  align-items: center;
  // justify-content: center;
  width: 100%;
  // min-width: 300px;

  // height: 100%;
  height: 40px;
  min-height: 40px;
}
 .sh-add-lesson-addcircle-pointer{
//  margin-top: 20px;
cursor: pointer;
 }

.box-shadow{
  width: 100%;
  // color: white;
  // height: 100px;
  background-color: white;
  // box-shadow: 5px 2px 5px 2px whitesmoke ;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}

.sh-add-lesson-typography-label{
  width: 40%;
  display: flex;
  align-items: center;
}
.sh-add-lesson-input-label{
  display: flex;
  align-items: center; 
  width: 55%;
  min-width: 300px;
}
.sh-addlesson-addcircle{
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sh-addcircle-outline-con{
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sh-add-lesson-video-id{
  font-size: 12px;
  margin-top: 1em;
  font-weight: bold;
}
.sh-progress-bar-margin-top{
  margin-top: 1em;
}
.sh-add-lesson-uploadvideo{
   width: 100%; 
   margin-top: 30px;
   display: flex;
   flex-direction: column;
}
.sh-add-lesson-uploadvideo-typo{

  padding-bottom: 15px;
}
.sh-add-lession-admin-dropzone-file-upload-title-con{
  width: 100%;
  border-radius: 10px;
}

.sh-add-lesson-upload-button{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;
  padding-bottom: 10px;
}
.sh-ad-lesson-Rbutton-cancel-add{
  width: 100%;
  display: flex;
  align-items: right ;
  padding-top: 55px;
}
.sh-add-lesson-select-input-div{
  display: flex;
  align-items: center;
  width: 100%;
}
.sh-add-lesson-select-input-div .css-placeholder {
  color: #999; 
  opacity: 1;
}
.sh-add-module-other-description{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sh-add-lesson-cancel-button{
  border-radius: 5px; 
  margin-right: 8px;
  background-color: none;
}
.sh-addlesson-enter-lesson-name-page-margin-top{
  margin-top: 30px;

}
.text-field-width{
  width:100%;
}
.sh-add-lesson-width{
  width: 40%;
  padding-left: 40px;
}
.sh-add-module-other-desc{
  top: 100%;
  left: 0;
  width: 100%;
}
.sh-add-module-other-desc-label{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.sh-module-intro-add-btn{
  transition: 0.2s ease-in-out scale;
}
.sh-add-lesson-cancel-border{
  border: 1px solid;
}
.sh-add-lesson-select-label-input{
  display: flex;
  flex-direction: column;
  // gap: 10px;
}
.sh-add-lesson-fa-cloud-upload{
  margin-right: 10;
  cursor: pointer;
  font-size: 16px;

}
.sh-style-add-level-page-select{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sh-add-module-error-message{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.sh-ad-lesson-Rbutton-cancel-flex{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;
}
.sh-module-intro-add-btn:hover{
  scale: 1.1;
}
.sh-add-lesson-dropzone{
  // width: 100%;
  height: 17em;
  margin-top: 15px;
}

.type-con-div {
  width: 100%;
}

.sh-list-lesson-refresh-icon-col{
  width: 7%;
  text-align: right;
}
.sh-list-lesson-refresh-icon-button{
  height: 30px;
  width: 65px;
  min-width: 40px;
  border: none;
}
.sh-list-lesson-btn{
  border: none ;
  height: 30px;
}

// Add level start
.sh-add-level-select-course{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sh-add-module-label{
  min-height: 2.5em;
}
.sh-list-level-height{
height: 30px;
}
.refresh-icon-rotate{
  font-size: 15px;
}
.sh-add-level-page-parent-row{
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

}

// Add lesson end

  // Dashboard start
  .admin-dash-board-back-title-con {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
  }
  .sh-select-course{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .css-uegchq-control{
    height: 40px;
    width: 100%;
  }
  .css-1lmosp6-control{
    width: 100%;
  }
  // Dashboard end

  // add-level start
  .sh-add-level-head-button-alignment{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sh-add-level-error-msg-alignment{
    width:50%;
    padding-left: 15px;
  }
  .sh-add-level-button-alignment{
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
  
  .sh-add-module-select-label{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .sh-add-module-select{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .sh-add-level-border{
    border: 1px solid;
  }
  .sh-add-level-padding-top{
    padding-top: 100px;
  }
  .sh-add-level-description{
    font-size: 13px;
    min-height: 6em;

  }
  .sh-add-level-description-2{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .sh-add-level-formcontrol{
    display: flex;
    align-items: center;
  }
  .sh-addlevel-modal-padding{
    padding: 15px 7px 0px;
  }

// Add Module Modal start
.sh-add-module-padding{
  padding: 15px 7px 0px 15px;
}
.sh-list-module-refresh-icon-button{
  height: 35px;
  width: 65px;
  min-width: 40px;
  border: none;
}
.list-module-add-col-button{
  width: 7%;
  text-align: right;

}
.list-module-refresh-font-size{
  font-size: 15px;
}
.sh-add-module-fontsize-minheight{
  font-size: 13px;
  min-height: 6em;

}

.sh-add-module-tittle-close-btn-con{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.sh-add-lesson-typography-label-page{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sh-other-desc-input-form{
  width: 100%;
  overflow: auto;
  resize: none;
  box-sizing: "border-box";
  padding: 10px;
}
.sh-add-module-tittle-close-btn-con-col-1, .sh-add-module-tittle-close-btn-con-col-2, .sh-add-select-course-con, .sh-add-module-name-con{
  width: 50%;
}
.sh-add-module-tittle-close-btn-con-col-2{
  text-align: right;
}
.sh-add-module-modal-close-btn-icon:hover{
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 5px;
}
.css-83j2yf-control{
  height: 40px;
}
.sh-add-module-name-label{
  min-height: 2em;
}
.sh-add-module-select-course{
  margin-top: -8px;
}
// .sh-add-module-name-text-field > label {
//   line-height: 1.1375em;
// }
.sh-add-module-name-text-field > div > input {
  background-color: #fff !important;
  background: #fff !important;
}
.sh-add-module-description-con-col{
  width: 100%;
  padding: 15px 11px;
}
.sh-add-module-description-con-col1{
  width: 100%;
  padding: 15px 11px;
}
.sh-add-module-description-label{
  margin-bottom: 1em;
  margin-left: 4px;
}
.sh-add-module-select-module-con, .sh-add-module-multi-description-add-con{
  padding: 0px 15px;
}
.sh-add-module-select-module-con1{
  padding: 0px 4px 0px 11px;
}
.sh-add-module-multi-description-label{
  margin-bottom: 1em;
}
.sh-add-module-media-upload-dropzone-col-con{
  width: 100%;
}
.sh-add-module-media-upload-dropzone-col > div > div > div > div > div > svg {
  font-size: 4em;
}
.sh-add-module-add-cancel-btn-con{
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sh-add-module-multi-description-chip-con{
  width: fit-content;
  padding: 4px 7px;
  display: inline-block;
  background: #0000007d;
  color: white;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 12px;
  word-break: break-all;
}
.sh-add-module-multi-description-chip-close-icon{
  color: #000;
  font-size: 17px;
  margin-left: 8px;
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
}
.sh-add-module-multi-description-chip-close-icon:hover{
  background: #000;
  color: white;
}
.sh-add-module-font-size{
  font-size: 13px;
}
// .sh-add-module-name-text-field-level{
//   padding: 10px 12px 10px 15px;
// }
// Add Module Modal end

// admin list module start


.sh-add-module-pad{
  padding-top: 15px;
}
.sh-module-moduleprice{
  padding: 0px 0px 10px 0px;
}

.sh-admin-modules-list-page-con{
  width: 100%;
  display: flex;
  align-items: center;
}
.sh-admin-modlues-list-page-title-content{
  width: 45%;
  display: flex;
  align-content: flex-start;
  font-size: 1.5em;
  font-style: italic;
}
.sh-addlesson-enter-lesson-name-page{
  // width: 100%;
  margin-top: 20px;
  display: flex; 
  align-items: center;
  // justify-content: center;
}
.sh-add-module-width{
  width: 100%;
}
.back-button-width{
  width: 5%;
}
.module-list-add-width{
  width: 43%;
  text-align: right;
  padding-right: 15px;
}
.module-list-add-height{
  border: none;
  height: 30px;
}
.module-list-data-table{
  margin-top: 25px;
}
.module-list-data-table-col{
  width: 100%;
}

.module-list-data-table-col .rdt_TableHeader {
  background-color: transparent !important;
  font-weight: bold;
  font-size: 14px;
  padding: 10px;
}

.sh-list-lesson-refresh-icon-button{
  height: 30px;
  width: 65px;
  min-width: 40px;
  border: none;
}

.sw-date-range-option {
  font-size: 12px;
}

// admin list module end

// delete modal start
.delete-modal-confirm-label{
  float: left;
  padding-left: 5px;
}
.delete-modal-clearIcon{
  float: right;
  cursor: pointer;
}
.delete-modal-delete-label{
  padding-top: 15px;
  min-height: 90px;
}
.delete-modal-cancel-delete-btn{
  display: flex;
  justify-content: flex-end;
}
.delete-modal-delete-typo-height{
  line-height: 20px;
}
.sh-delete-model-alert-icon{
  font-size: 18px;
  margin-top: 2px;
}

// delete modal end

// add Module page start
.addModule-back-width{
  width: 5%;
}
.add-module-select-course-col{
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: "0px";
}

// Refresh icon start

// Refresh icon end

.sh-add-refresh-rotate-icon{
  transform: rotate(360deg);
  transition: transform 0.3s ease-in-out;
}

// .sh-user-profile-name-type-con > div:nth-child(2){
//   position: relative;
//   left: -16px;
// }

// player start

.video-player-main-con {
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-player-con {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 338px;
}

.swara-video-page-titile-con {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    column-gap: 10px;
}

/* shaka-player.css */
.shaka-video-container {
  position: relative;
  overflow: hidden;
}

.shaka-video-container video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.shaka-controls-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  padding: 10px;
}

.shaka-controls-container button {
  border: none;
  font-size: 1rem !important;
  cursor: pointer;
}


.shaka-controls-container {
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  background: transparent;
}

/* Style adjustments for a better appearance */
.shaka-controls-container .shaka-bottom-controls {
  background-color: transparent !important;
}
.shaka-controls-container .shaka-bottom-controls:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.shaka-controls-container .shaka-seekbar-container {
  display: flex;
  align-items: center;
}

.shaka-controls-container .shaka-seekbar {
  margin: 0 10px;
  width: calc(100% - 20px);
}

/* Custom styles for the buttons */
.shaka-controls-container .shaka-button-container > .shaka-button {
  color: white;
  font-size: 16px;
  margin: 0 5px;
}sh-announcement-cards-container

.shaka-overflow-button-label {
  font-size: 12px !important;
}

.shaka-overflow-menu, .shaka-settings-menu {
  row-gap: 10px !important;
  padding: 10px 0 !important;
}

.shaka-settings-menu span {
  font-size: 12px !important;
}

.soul-video-lesson-list-con {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.soul-video-lesson-list-col-con {

  padding: 16px !important;
  border-radius: 5px;

}

.soul-video-lesson-list-accordion-con .rs-panel-btn:hover, .soul-video-lesson-list-accordion-con .rs-panel-btn:active,
.soul-video-lesson-list-accordion-con .rs-panel-btn:focus,
.soul-video-lesson-list-accordion-con .rs-panel-btn:visited,
.soul-video-lesson-list-accordion-con .rs-panel-btn:focus-visible {
  background-color: rgb(30, 30, 30) !important;
  color: white !important;
}

.soul-video-lesson-list-accordion-con .rs-panel-collapsible > .rs-panel-header {
  padding: 3px !important;
}

.soul-video-lesson-list-accordion-con .rs-panel-title {
  font-size: 14px !important;
  font-weight: 500;
}

.soul-video-lesson-list-title {
  padding: 16px;
  padding-top: 0px;
}

.rs-panel-group > .rs-panel + .rs-panel::before {
  border-top: none !important;
}



// player end


.comment-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.comment-thread {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.chat-bubble {
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 60%;
  word-wrap: break-word;
  margin-bottom: 10px;
  position: relative;
}

.user-bubble {
  background-color: #d1e7dd;
  align-self: flex-start;
}

.admin-bubble {
  background-color: #f8d7da;
  margin-left: 2em;
}

.comment-date {
  font-size: 0.8em;
  color: #555;
  margin-top: 5px;
}

.reply-icon {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

.comment-form, .reply-form {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.comment-form input, .reply-form input {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.comment-form button, .reply-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.comment-form button:hover, .reply-form button:hover {
  background-color: #0056b3;
}

.sh-upload-reference-doc-content {
  padding: 15px;
}

.footer-content-link {
  cursor: pointer;
}

.sh-landing-page-refund-page-link-word:hover{
  text-decoration: none;
}

.footer-content-link:hover {
  border-bottom: 1px solid;
  text-shadow: 1px 1px 1px #000;
}
.rs-modal-content{
  background-color: inherit !important;
}

.sh-payment-pricing-toggle-btn-con {
  display: flex;
  align-items: center;
  column-gap: 1em;
  justify-content: space-between;
}

.sh-payment-page-section-con {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 82vh;
  min-height: 82vh;
}

.sh-payment-page-section-package-icon-con{
  border-radius: 18px 18px 0px 0px;
  padding: 1em;
}

.sh-payment-page-section-package-details-con {
  width: 100% !important;
  margin: auto !important;
  // border-radius: 0px 0px 18px 18px !important;
}

.com-comment-text-con {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-end;
}
.com-comment-text-content {
  padding: 5px 16px;
  border-radius: 10px;
  font-size: 0.75rem !important;
}

.com-comment-by-text-con {
  font-size: 0.75rem !important;
  text-align: right;
  padding: 0px 5px 3px;
  opacity: 0.5;
}

.com-comment-main-content-con {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 1em;
}

.soul-com-comment-section {
  margin-bottom: 10px;
  min-height: 40vh;
  max-height: 40vh;
  overflow: auto;
  padding: 0px 10px;
}

.soul-com-comment-section::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

.soul-add-post-upload-image-btn-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5em;
  column-gap: 10px;
}

.sh-edit-user-profile-drop-down-component-con{
  height: 18em;
}

.sh-upload-reference-doc-content-file{
  height: 15.2em;
}

.sh-payment-price-con {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  font-weight: 500;
  font-style: oblique;
}

.shaka-play-button {
  padding: 2em !important;
}

.sh-landing-page-section-7-row-con > .sh-landing-page-section-4-title{
  font-size: 35px !important;
}

// CK Editor start

// @media print {
// 	body {
// 		margin: 0 !important;
// 	}
// }

:root {

  background-color: #161616 !important;
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 4px;

  /* Overrides the default font size in the theme. */
  --ck-font-size-base: 14px;

  /* Helper variables to avoid duplication in the colors. */
  --ck-custom-background: hsl(270, 1%, 29%);
  --ck-custom-foreground: hsl(255, 3%, 18%);
  --ck-custom-border: hsl(300, 1%, 22%);
  --ck-custom-white: hsl(0, 0%, 100%);

  /* -- Overrides generic colors. ------------------------------------------------------------- */

  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: hsl(208, 90%, 62%);
  --ck-color-text: hsl(0, 0%, 98%);
  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: hsl(270, 1%, 22%);
  --ck-color-button-default-active-background: hsl(270, 2%, 20%);
  --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-on-background: var(--ck-custom-foreground);
  --ck-color-button-on-hover-background: hsl(255, 4%, 16%);
  --ck-color-button-on-active-background: hsl(255, 4%, 14%);
  --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

  --ck-color-button-action-background: hsl(168, 76%, 42%);
  --ck-color-button-action-hover-background: hsl(168, 76%, 38%);
  --ck-color-button-action-active-background: hsl(168, 76%, 36%);
  --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
  --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
  --ck-color-button-action-text: var(--ck-custom-white);

  --ck-color-button-save: hsl(120, 100%, 46%);
  --ck-color-button-cancel: hsl(15, 100%, 56%);

  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

  --ck-color-dropdown-panel-background: var(--ck-custom-background);
  --ck-color-dropdown-panel-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-dialog class colors. ----------------------------------- */

  --ck-color-dialog-background: var(--ck-custom-background);
  --ck-color-dialog-form-header-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

  --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-split-button-hover-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

  --ck-color-input-background: var(--ck-custom-background);
  --ck-color-input-border: hsl(257, 3%, 43%);
  --ck-color-input-text: hsl(0, 0%, 98%);
  --ck-color-input-disabled-background: hsl(255, 4%, 21%);
  --ck-color-input-disabled-border: hsl(250, 3%, 38%);
  --ck-color-input-disabled-text: hsl(0, 0%, 78%);

  /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

  --ck-color-labeled-field-label-background: var(--ck-custom-background);

  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

  --ck-color-list-background: var(--ck-custom-background);
  --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
  --ck-color-list-button-on-background: var(--ck-color-base-active);
  --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
  --ck-color-list-button-on-text: var(--ck-color-base-background);

  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

  --ck-color-panel-background: var(--ck-custom-background);
  --ck-color-panel-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

  --ck-color-tooltip-background: hsl(252, 7%, 14%);
  --ck-color-tooltip-text: hsl(0, 0%, 93%);

  /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);

  /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

  --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
  --ck-color-widget-hover-border: hsl(43, 100%, 68%);
  --ck-color-widget-editable-focus-background: var(--ck-custom-white);

  /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

  --ck-color-link-default: hsl(190, 100%, 75%);
}

.main-container {
	font-family: 'Lato';
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
  width: 100%;

}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
	// min-width: 795px;
	// max-width: 795px;
  width: 100%;
}
.ck-editor__editable_inline {
  min-height: 25em;
  color: var(--text-color) !important;
}

.editor-containter-width{
  width: 100%;
}
// CK Editor end

.add-post-community-page-add-post-cancel-btn-con{
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
}

.add-post-community-page-image-upload-content-con{
  min-height: 3em;
}

.sh-announcement-page-dropzone-con{
  width: 80%;
}

.sh-announcement-page-right-con-container{
  width: 60%; 
}
.sh-announcement-page-left-label-side-left{
  width: 20%;
}

.sh-announcement-page-right-side-con-container{
  width: 40%;
}

.sh-announce-ment-page-upload-image-label{
  margin-bottom: 3.5em;
}

.sh-announcement-page-post-title-content-label{
  margin-bottom: 1em;
}

.sh-announcement-page-back-btn-container-con{
  margin-bottom: 2em;
}

.sh-announcemet-page-image-upload-cancel-btn-con{
  margin-top: 2em;
}

.sh-community-page-image{
  width: 12em;
}

.sh-communiy-page-image-content-con-admin{
  display: flex;
  justify-content: space-between;
  min-height: 18em;
  border-radius: 10px;
  border: 1px solid #707070;
}

.sh-announcement-page-like-button-fill-icon{
  width: 0.9em;
}

.sh-announcement-page-post-content-container-con{
  // display       : "flex",
  // justifyContent: "center",
  // marginTop     : "10px",
  // height        : "15em"
  // display: flex;
  // justify-content: center;
  height: inherit;
  overflow: hidden;
  border-radius: 10px;
  border-right: 1px solid #707070;
  // text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sh-announcement-banner-image-text-container{
  width: 106%;
  margin-left: -50px;
  margin-right: -40px;
  position: relative;
  margin-bottom: 3em;
}

.sh-community-page-banner-image{
  width: 100%;
}

.sh-community-page-banner-content{
  position: absolute;
  top: 50%;
  margin-left: 5vw;
}
.sh-community-page-content-left-side-con > div {
  height: 100%;
}

.sh-user-intro-continue-btn{
  font-size: 16px;
  padding: 10px 30px;
}

.sh-list-couse-tab-course-text > span{
  font-size: 16px;
}

.sw-admin-purchase-history-header-con {
  margin: 1em 0;
  display: flex;
  align-items: center;
}
.sw-admin-purchase-history-date-range-con {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
}

// media query start

@media (max-width: 575.98px) {
  /* Styles for phones (portrait) */
  .sh-announcement-card-con{
    width: 100%;
  }
  .sh-landing-page-section-7-row-con > .sh-landing-page-section-4-title{
    font-size: 20px !important;
  }
  .sh-announcement-banner-text{
    font-size: 15px;
  }
  .sh-announcement-banner-image-text-container{
    width: 95%;
    margin-left: unset;
    margin-right: unset;
    position: relative;
    margin-bottom: 3em;
  }
  .sh-community-page-banner-content{
    top: 40%;
  }
  .sh-communiy-page-image-content-con-admin{
    display: block;
    height: 100%;
  }
  .sh-community-page-content-right-side-con{
    width: 100%;
  }
  .sh-community-page-content-left-side-con > div {
    height: 18em;
  }
  .sh-community-page-content-left-side-con{
    width: 100%;
    height: 18em;
  }
  .sh-community-page-content-right-side-con{
    width: 100%;
  }
  .sh-announcement-card-post-content{
    margin-bottom: 1em;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  /* Styles for landscape phones and small tablets */
  .sh-announcement-card-con{
    width: 100%;
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {


  .sh-landing-page-landing-image-left-cover-con, .sh-landing-page-landing-content-right-side-con {
    width: 100% !important;
    text-align: center !important;
  }
  .web-hub-title{
    text-align: center !important;
    margin-top: 1em !important;
    font-size: 1.5em !important;
    line-height: 1.3 !important;
  }
  .web-hub-title-sub{
    text-align: center !important;
  }
  .sh-list-modules-filter-tab{
    margin-bottom: 1em !important;
  }
  .sh-user-profile-user-details-con{
    margin-left: 0px !important;
    text-align: center;
  }

  .sh-user-edit-profile-basic-secleted-tab-title-cover{
    font-size: 14px;
  }
  .sh-edit-user-profile-mobile-view-basic-info-con > .css-13xfq8m-MuiTabPanel-root{
    padding: 0px !important;
    padding-top: 20px !important;
  }
  .sh-user-edit-page-mobile-view-view-profile-button{
    display: flex;
    gap: 10px;
    margin-bottom: 1em;
  }
  .sh-user-profile-basic-info-edit-btn-mobile-none{
    display: none;
  }
  .sh-module-intro-row{
    display: block;
  }
  .sh-module-intro-column-left, .sh-module-intro-column-right, .sh-module-intro-left-side-description-content{
    width: 100%;
  }
  .sh-module-intro-column-left{
    padding-bottom: 5em;
  }
  .sh-module-intro-left-side-title-content{
    font-size: 1.5rem;
    line-height: 1.4;
  }
  .sh-announcement-card-con{
    width: 100%;
  }
  .sh-announcement-banner-text{
    font-size: 15px;
  }
  .sh-announcement-banner-image-text-container{
    width: 95%;
    margin-left: unset;
    margin-right: unset;
    position: relative;
    margin-bottom: 3em;
  }
  .sh-community-page-banner-content{
    top: 40%;
  }
  .sh-communiy-page-image-content-con-admin{
    display: block;
    height: 100%;
  }
  .sh-community-page-content-right-side-con{
    width: 100%;
  }
  .sh-community-page-content-left-side-con > div {
    height: 18em;
  }
  .sh-community-page-content-left-side-con{
    width: 100%;
  }
  .sh-community-page-content-right-side-con{
    width: 100%;
  }
  .sh-announcement-card-post-content{
    margin-bottom: 1em;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Styles for small desktops and large tablets */
}
